<template>
  <div>
    <div class="mt-8 mb-8 d-flex align-center justify-space-between">
      <div class="d-flex">
        <botao-padrao
          color="secondary"
          outlined
          :disabled="!tabela.selecionados.length"
          @click="movimentarItem('cima')"
        >
          <v-icon> $arrowUp </v-icon>
        </botao-padrao>

        <botao-padrao
          color="secondary"
          outlined
          class="mx-2"
          :disabled="!tabela.selecionados.length"
          @click="movimentarItem('baixo')"
        >
          <v-icon> $arrowDown </v-icon>
        </botao-padrao>

        <botao-padrao
          color="secondary"
          outlined
          @click="salvarOrdenacao"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar_ordenacao') }}
        </botao-padrao>

        <botao-padrao
          v-if="podeExcluir"
          class="ml-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>

      <botao-padrao
        @click="abrirNovo"
        class="ml-2"
      >
        {{ $t('modulos.kanban_configuracao.titulos.adicionar_coluna') }}
      </botao-padrao>
    </div>

    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      @paginar="listarRegistros"
    >
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          v-if="buscarPermissao('Kanban', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>

      <template v-slot:item.sequencia="{ item }">
         {{ item.sequencia }}
      </template>

      <template v-slot:item.corHexaFundoColuna="{ item }">
        <div
          style="width: 100%; height: 100%"
          class="d-flex align-center justify-center color-picker-column"
        >
          <color-picker
            v-model="item.item.corHexaFundoColuna"
            style="width: 100%"
            format="hex"
            input-id="cp-hex"
            disabled
          />
        </div>
      </template>

      <template v-slot:item.corHexaFonteCartao="{ item }">
        <div
          style="width: 100%; height: 100%"
          class="d-flex align-center justify-center color-picker-column"
        >
          <color-picker
            v-model="item.item.corHexaFonteCartao"
            style="width: 100%"
            format="hex"
            input-id="cp-hex"
            disabled
          />
        </div>
      </template>

      <template v-slot:item.corHexaFundoCartao="{ item }">
        <div
          style="width: 100%; height: 100%"
          class="d-flex align-center justify-center color-picker-column"
        >
          <color-picker
            v-model="item.item.corHexaFundoCartao"
            format="hex"
            style="width: 100%"
            input-id="cp-hex"
            disabled
          />
        </div>
      </template>

      <template v-slot:item.corHexaFonteCabecalhoCartao="{ item }">
        <div
          style="width: 100%; height: 100%"
          class="d-flex align-center justify-center color-picker-column"
        >
          <color-picker
            v-model="item.item.corHexaFonteCabecalhoCartao"
            format="hex"
            style="width: 100%"
            input-id="cp-hex"
            disabled
          />
        </div>
      </template>
    </tabela-padrao>
  </div>
</template>

<script>
import KanbanConfiguracaoEnumeradorSituacoesService from '@common/services/cadastros/KanbanConfiguracaoEnumeradorSituacoesService';
import KanbanConfiguracaoService from '@common/services/cadastros/KanbanConfiguracaoService';
import SetorService from '@common/services/cadastros/SetorService';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import BotaoPadrao from '@/components/buttons/BotaoPadrao.vue';
import helpers from '@common/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  components: { BotaoPadrao },
  data() {
    return {
      options: {
        setores: [],
        situacoes: [],
      },
      setorId: null,
      setor: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nomeColuna',
            text: this.$t('modulos.kanban_configuracao.tabela.nome_coluna'),
            sortable: true,
          },
          {
            value: 'situacoes',
            text: this.$t('modulos.kanban_configuracao.tabela.situacoes'),
            sortable: true,
            formatter: (situacao) => {
              if (situacao) {
                const situacoes = this.options.situacoes.filter((item) =>
                  situacao.includes(item.id)
                );
                return situacoes.map((item) => item.descricao).join(', ');
              }
            },
          },
          {
            value: 'quantidadeCartoesLinha',
            text: this.$t(
              'modulos.kanban_configuracao.tabela.quantidade_cartoes_por_linha'
            ),
            sortable: true,
            width: 170,
          },
          {
            value: 'quantidadeCartoesColuna',
            text: this.$t(
              'modulos.kanban_configuracao.tabela.quantidade_cartoes_por_coluna'
            ),
            sortable: true,
            width: 185,
          },
          {
            value: 'corHexaFundoColuna',
            text: this.$t('modulos.kanban_configuracao.tabela.cor_coluna'),
            sortable: true,
            width: 150,
          },
          {
            value: 'corHexaFonteCabecalhoCartao',
            text: this.$t('modulos.kanban_configuracao.tabela.cor_fonte'),
            sortable: true,
            width: 150,
          },
          {
            value: 'corHexaFundoCartao',
            text: this.$t('modulos.kanban_configuracao.tabela.cor_cartao'),
            sortable: true,
            width: 150,
          },
          {
            value: 'corHexaFonteCartao',
            text: this.$t('modulos.kanban_configuracao.tabela.cor_fonte_cartao'),
            sortable: true,
            width: 150,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      enumClassificacao: [],
    };
  },

  computed: {
    ...mapGetters('Autenticacao', ['participanteSelecionado']),
    ...mapGetters('Autenticacao', ['usuario']),

    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    }
  },

  watch: {
    setorId() {
      this.listarRegistros();
    },
  },

  created() {
    helpers.redirecionarSemPermissao(this, 'Kanban', 'Listar');
  },

  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.kanban_configuracao.titulos.listagem')
    );

    const params = {
      ParticipanteId: this.participanteSelecionado,
      AmountPerPage: 20000,
    };
    SetorService.listarSetoresParticipantesConta(params).then((response) => {
      this.options.setores = new DropdownModel(response.data.items);
      this.setorInicialmenteSelecionado();
    });
  },

  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },

    setorInicialmenteSelecionado() {
      this.setorId = this.$route.params.setorId;
      this.setor = this.options.setores.find(
        (setor) => setor.value === this.setorId
      );
    },

    buscarSituacoes: function () {
      KanbanConfiguracaoEnumeradorSituacoesService.buscar('situacoes').then(
        (response) => {
          this.options.situacoes = response;
        }
      );
    },

    listarRegistros: async function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        setorId: this.setorId,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.buscarSituacoes();

      KanbanConfiguracaoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items.sort((a, b) => a.sequencia - b.sequencia);
          this.limparVaziosTabela();
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    limparVaziosTabela: function () {
      this.tabela.dados = this.tabela.dados.filter((el) => el);
    },

    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        this.tabela.selecionados.forEach((item) => {
          KanbanConfiguracaoService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(
                this.$t(`modulos.kanban_configuracao.exclusao_sucesso`)
              );
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.limparVaziosTabela();
              this.tabela.selecionados = [];
            });
        });
      });
    },

    abrirNovo: function () {
      this.$router.push({
        name: 'kanban-coluna-novo',
        params: {
          id: this.setorId,
        },
      });
    },

    abrirEditar: function (item) {
      this.$router.push({
        name: 'kanban-coluna-editar',
        params: {
          id: item.id,
          setorId: this.setorId,
        },
      });
    },

    movimentarItem: function (direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.tabela.dados.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) =>
        incremento === -1 ? a.index - b.index : b.index - a.index
      );

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.tabela.dados];
        const novoIndex = item.index + incremento;

        if (novoIndex < 0 || novoIndex >= novaLista.length) return;

        const itemListaAntes = novaLista[item.index + incremento];

        novaLista[item.index + incremento] = novaLista[item.index];
        novaLista[item.index] = itemListaAntes;
        this.tabela.dados = [...novaLista];
      });
    },

    salvarOrdenacao: function () {
      this.limparVaziosTabela();
      this.$store.dispatch('Layout/iniciarCarregamento');
      KanbanConfiguracaoService.salvarOrdenacao({
        ids: this.tabela.dados.map((m) => m.id),
      })
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.kanban_configuracao.ordenacao_sucesso`)
          );
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tabela.selecionados = [];
        });
    },
  },
};
</script>

<style>
.header {
  align-items: center;
}
.color-picker-column .p-colorpicker-preview {
  width: 100% !important;
}
.input-col-md-2 {
  max-width: 350px !important;
  flex: none;
}
.input-col-12 {
  max-width: 350px !important;
}

@media screen and (max-width: 826px) {
  .container-header {
    flex-direction: column;
    align-items: start;
  }
  .container-wrapper {
    display: block;
    width: 100%;
  }

  .text {
    margin-right: 0;
    margin-left: 0;
  }
  .container-update {
    margin: 12px 0 12px;
  }

  .header {
    flex-direction: column;
    align-items: start;
  }
  .ui_container_kanban_header {
    align-self: end !important;
  }
}
</style>

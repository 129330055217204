import { BasicoApi } from '@common/api';

const KanbanConfiguracaoEnumeradorSituacoesService= {
  buscar: function (resource) {
   return new Promise((resolve, reject) => {
     BasicoApi.get()(`/KanbanConfiguracao/${resource}`).then(res => {
        resolve(res.data)
    }).catch(() => {
      reject([])
    })

   })
  },
};

export default KanbanConfiguracaoEnumeradorSituacoesService;

import { BasicoApi } from '@common/api';
const KanbanConfiguracaoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/KanbanConfiguracao', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/KanbanConfiguracao/${id}`);
  },
  ultimaSequencia:function(id){
    return BasicoApi.get()(`/KanbanConfiguracao?SetorId=${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/KanbanConfiguracao`, form);
    return BasicoApi.post()('/KanbanConfiguracao', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/KanbanConfiguracao/${id}`);
  },
  salvarOrdenacao: function (lista) {
    return BasicoApi.put()(`/KanbanConfiguracao/atualiza-lista`, lista);
  },
};

export default KanbanConfiguracaoService;

<template>
  <div>
    <template>
      <div class="mb-8 ">
        <div class="titulo-pagina">
          {{ tituloFormulario }}
        </div>
        <div v-if="setor">
          <v-chip
            class="mr-1"
            small
          >
            Tipo: {{ setor }}
          </v-chip>
        </div>
      </div>
      <v-tabs
        v-model="indiceAba"
        class="abas-padrao"
        background-color="primary"
        color="primary"
      >
        <v-tab v-on:click="alterarAba(0)">
          {{
            $t(
              'modulos.kanban_configuracao.titulos.coluna'
            )
          }}
        </v-tab>
        <v-tab v-on:click="alterarAba(1)">
          {{ $t('modulos.kanban_configuracao.titulos.cartao') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="indiceAba">
        <v-tab-item>
          <ConfiguracaoColuna
            :participante-logado-conta="participanteLogadoConta"
            :visualizar="visualizar"
          />
        </v-tab-item>
        <v-tab-item>
          <ConfiguracaoCartao
            :participante-logado-conta="participanteLogadoConta"
            :visualizar="visualizar"
          />
        </v-tab-item>
      </v-tabs-items>
      <template>
        <v-divider class="my-5" />
        <div
          class="d-flex align-center justify-end"
         >
          <botao-padrao
            outlined
            color="secondary"
            class="mr-2"
            @click="cancelar"
          >
            <v-icon>{{ '$mdiArrowLeft'  }}</v-icon>
            {{ botaoVoltar }}
          </botao-padrao>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ConfiguracaoColuna from './components/ConfiguracaoColuna.vue';
import ConfiguracaoCartao from './components/ConfiguracaoCartao.vue';
import { mapGetters, mapActions } from 'vuex';
import SetorService from '@common/services/cadastros/SetorService';
import helpers from '@common/utils/helpers';

export default {
  components: { ConfiguracaoColuna, ConfiguracaoCartao },

  props: {
    id: { type: String, default: '' },
    visualizar: { type: Boolean, default: false },
  },

  data() {
    return {
      indiceAba: 0,
      valido: false,
      setor: null,
      setorId: null,
      tituloFormulario: this.$t('modulos.kanban_configuracao.titulos.listagem'),
    };
  },

  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    ...mapGetters(['abaKanban']),

    botaoVoltar() {
      return this.$t('geral.botoes.voltar');
    },
  },

  watch: {
    id() {
      if (this.id) this.buscar();
    },
  },

  mounted() {
    this.setorId = this.$route.params.setorId;
    if (this.setorId) {
      SetorService.buscar(this.setorId).then((response) => {
        this.setor = response.data.nome;
      });
    }

    if (!this.visualizar) this.visualizar = this.$route.path.includes('visualizar');

    if (this.id) {
      this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'TipoInstrumento', 'Editar');
    }

    this.verificarEdicaoFaixa();

    this.indiceAba = this.abaKanban;
  },

  methods: {
    ...mapActions(['setAbaKanban']),

    verificarEdicaoFaixa: async function (){
      await this.$nextTick();
      if(this.$route.query?.editarFaixa) this.indiceAba = 1;
    },

    alterarAba(aba) {
      this.setAbaKanban(aba);
    },

    cancelar() {
      this.setAbaKanban(0);
      this.$router.push({
        name: 'kanban',
        params: { value: this.setorId, text: this.setor }
      });
    },
  },
};
</script>
